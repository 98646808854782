

var key = {
  NetworkId: 56,
  netWorkUrl: "https://bsc-dataseed1.binance.org/",
  Factory: "",
  Router :"",
  ETHSYMBOL: "BNB"
};

// if (process.env.NODE_ENV == "production") {
//   key = {
//     contractAddress: "0x67E496c293d991c684A04F569d6144eAa78d3a40",
//     // tokenAddress:"0xe9e7cea3dedca5984780bafc599bd69add087d56",
//     tokenAddress:"0x55d398326f99059fF775485246999027B3197955",
//     frontUrl: "https://www.elitevisionnetwork.com/",
//     baseUrl: "https://productionapi.elitevisionnetwork.com/userapi",
//     serverURL: "https://productionapi.elitevisionnetwork.com/",
//     NetworkId: 56,
//     networkName: "Binance Chain",
//     toFixed: 8,
//     txUrl: "https://bscscan.com/tx/",
//     txUrlAddress: "https://bscscan.com/address/",
//     nativeCurrency: {
//       name: 'Binance',
//       symbol: 'ETH', // 2-6 characters long
//       decimals: 18
//     },
//     netWorkUrl: "https://bsc-dataseed1.binance.org/",
//     blockexploer: "https://bscscan.com",
    
//     reactLoadr: "#19366b",   
//     rpcurl: "https://bsc-dataseed1.binance.org/",
//     ETHSYMBOL: "BNB",
//     limit: 6,
//     NetworkType: "Binance",
//   };
// } 

if (process.env.NODE_ENV == "demo") {
  key = {
    
    //demo
  	contractAddress: "0xd144f2d7eF2aaC808B30c2e7F0CB336d1c7F8327",
    tokenAddress:"0x8ce7720fd183aec96b676fd8250724b05b0d7a6f",
    frontUrl: "https://elite-frontend.pages.dev/",

    baseUrl: "",
    NetworkId: 97,
    networkName: "Binance Chain",
    toFixed: 8,
    txUrl: "https://testnet.bscscan.com/tx/",
    txUrlAddress: "https://testnet.bscscan.com/address/",
    nativeCurrency: {
      name: 'Binance',
      symbol: 'ETH', // 2-6 characters long
      decimals: 18
    },
    netWorkUrl: "https://data-seed-prebsc-2-s2.binance.org:8545/",
    blockexploer: "https://bscscan.com",
    
    reactLoadr: "#19366b",   
    rpcurl: "https://data-seed-prebsc-2-s2.binance.org:8545/",
    ETHSYMBOL: "BNB",
    limit: 6,
    NetworkType: "Binance",
  };
}
else{
  key = {
    
  
  	contractAddress: "0xd144f2d7eF2aaC808B30c2e7F0CB336d1c7F8327",
    tokenAddress:"0x8ce7720fd183aec96b676fd8250724b05b0d7a6f",
    frontUrl: "http://localhost:3000/",
    serverURL: 'http://localhost:2053/',
    baseUrl: "http://localhost:2053/userapi",
    NetworkId: 97,
    networkName: "Binance Chain",
    toFixed: 8,
    txUrl: "https://testnet.bscscan.com/tx/",
    txUrlAddress: "https://testnet.bscscan.com/address/",
    nativeCurrency: {
      name: 'Binance',
      symbol: 'ETH', // 2-6 characters long
      decimals: 18
    },
    netWorkUrl: "https://data-seed-prebsc-2-s2.binance.org:8545/",
    blockexploer: "https://bscscan.com",
    
    reactLoadr: "#19366b",   
    rpcurl: "https://data-seed-prebsc-2-s2.binance.org:8545/",
    ETHSYMBOL: "BNB",
    limit: 6,
    NetworkType: "Binance",
  };
}
export default key;